import React from 'react'

import Typography from '@material-ui/core/Typography'

import Layout from '../../layouts/Article'
import { LinkTab } from '../../components/Link'

const Default = () => {

  const mainView = (
    <Layout
      title="Vilém Flusser"
      subtitle="Exile and Creativity"
      url='/articles/villem-flusser-exile-and-creativity'
    >

      <Typography
        paragraph variant='subtitle1'
      >
        This essay will explore neither the existential nor the religious connotations of the concept of the term exile. However, we should keep in the back of our mind the Christian story of man’s expulsion from Paradise and his entrance into the world, the Jewish mystic’s story of the exile of divine spirit in the world, and the existentialist story of man as a stranger in the world. All of these stories should be kept in the back of our mind without being verbalized. For the intention here is to interpret the exile situation as a challenge to creative activity.
      </Typography>
      <Typography 
        paragraph variant='subtitle1'
      >
        This is the proposed hypothesis: The expelled has been torn out of his customary surroundings (or else he has done it himself. Habit is a blanket that covers up the facts of the case. In familiar surroundings, change is recognized, but not permanence.
      </Typography>
      <Typography 
        paragraph variant='subtitle1'
      >
        Before I begin defending this hypothesis, I want to point out that it proposes a positive assessment of expulsion. In a situation where one is accustomed to pitying the expelled, this positive assessment is itself unusual, and, according to the hypothesis, it should itself be informative.
      </Typography>
      <Typography 
        paragraph variant='subtitle1'
      >
        For it seems—according to this hypothesis—that those people who want to “help” the expelled to become ordinary again are, in fact, engaged in reeling him back into their ordinariness. This is an informative assumption, because it forces us to think about what is usual. The assumption does not justify the expellers, but rather, it exposes the vulgarity of the expellers: the expelled were bothersome factors who were expelled to make the surroundings even more ordinary than before. Indeed, this assumption leaves the following question to our discretion: Even without intending to do so, have the expellers not done the expelled a service? 
      </Typography>
      <Typography 
        paragraph variant='subtitle1'
      >
        I use the word expelled rather than refugees or emigrants, to bring the totality of the problem before our eyes. For I do not only refer to phenomena like the “boat people,” Palestinians, or Jewish emigration from Hitler’s Europe, but also, the expulsion of an older generation from the world of their children and grandchildren—or even the expulsion of humanists from the world of apparatuses. We find ourselves in a period of expulsion. If one values this situation positively, the future will appear a little less dark.
      </Typography>
      <Typography 
        paragraph variant='subtitle1'
      >
        This essay has been written by one who has been expelled not only many times, but also in a number of different ways. Thus, it comes from one who knows the suffering that characterizes every form of exile. Also, the shadow that this sort of suffering casts and for which the German language has coined the term Heimweh (“homesickness”). Nevertheless—or perhaps out of spite—this essay will praise expulsion.
      </Typography>
      <Typography 
        paragraph variant='subtitle1'
      >
        Habit is like a cotton blanket. It covers up all the sharp edges, and it dampens all noises. It is unaesthetic (from aisthesthai = perception), because it prevents bits of information from being perceived, as edges or noises. Because habit screens perceptions, because it anaesthetizes, it is considered comfortable. As comfy. Habit makes everything nice and quiet. Every comfortable surrounding is pretty, and this prettiness is one of the sources of love of the fatherland. (Which, indeed, confuses prettiness with beauty.) If the cotton blanket of habit is pulled back, one dis covers things. Everything becomes unusual, monstrous, in the true sense of the word un-settling. To understand this, it is quite enough to look at one’s right hand with all its finger movements from the perspective of a Martian: an octupus-like monstrosity. The Greeks called this “discovering” of the covered up aletheia, a word that we translate as “truth.”
      </Typography>
      <Typography 
        paragraph variant='subtitle1'
      >
       It is not as if we could actually be expelled from our right hand, unless, of course, we let it be amputated. Thus, when we discover how monstrous our bodily condition is, it is owing to our strange ability to expel our body from our thoughts. An exile as radical as this cannot be maintained for long: we are overcome with an irresistible homesickness for our own beautiful bodies, and we reimmigrate. Yet, this example of an extreme form of exile is instructive: For the expelled, it is almost as if he has been expelled from his own body. As if he was out of his mind. Even the usual things that he takes into exile are creepy. Everything around him and in him becomes sharp and noisy. He is driven to discovery, to truth.  
      </Typography>
      <Typography 
        paragraph variant='subtitle1'
      >
        The transcendence in which the expelled finds himself (as much as the word finds describes him, for in reality he is lost) causes everything around him and in him to appear provisory, transitory. In habit, only change is perceived; in exile, everything is perceived as if in the process of change. For the expelled, everything challenges him to change his life. In exile, where the blanket of habit has been pulled back, he becomes a revolutionary, if only because it enables him to live there. Thus, the suspicion that confronts the expelled in his New Land is completely justified. His ad vent in the New Land breaks through the usual and threatens its prettiness.
      </Typography>
      <Typography 
        paragraph variant='subtitle1'
      >
        Only for the expelled is the New Land truly new. Wherever he is driven, he discovers America. For the natives who must accept him, it is Old Land. Only the immigrant in America is truly an American, even if he should migrate to ancient lands (for example, to Jerusalem). His immigration into exile radiates an American atmosphere. Yet, from his perspective, it is something completely different: he is concerned with making the unusual livable (that is, everything). It is possible to shape a creative dialogue between the expelled and the original natives out of this reciprocal misunderstanding.
      </Typography>
      <Typography 
        paragraph variant='subtitle1'
      >
        It is not inconsequential where one is driven. Certainly, for the expelled himself, all exile is New Land. But, for the original natives, every land has its own character, that is, other habits that cover up the truth. There are lands that consider themselves new out of habit (for example, America or the land of our grandchildren or the land of automatic apparatuses). Also, there are lands that consider themselves old out of habit, which is to say “sacred” (for example, Jerusalem or the land of linear texts or the land of bourgeois values). If the expelled moves into a land that considers itself new, then the original natives are forced to uncover their senility petrified by habit. And, if he moves into a land that considers itself sacred, then the original natives are forced to uncover their sacred ness as habit. On the one hand, he forces the Americans, the grand children, and the apparatus functionaries to uncover themselves as something that has always existed. On the other hand, he forces the citizens of Jerusalem, the authors, and the defenders of eternal values to uncover themselves as lazy creatures of habit. In this manner, the creative dialogue between the expelled and the original natives can be divided into two types. The one type (such as the dialogue between an expelled and a New Yorker) will bring renewal through information; the other type (that between an expelled and a citizen of Jerusalem) will bring de sacralization through information. This classification is important for an understanding of the present (such as the phenomenon of the so-called guest workers or the phenomenon of the critique of apparatuses, as has been advanced in Germany by the Greens).
      </Typography>
      <Typography 
        paragraph variant='subtitle1'
      >
        The expelled are uprooted people who attempt to uproot everything around themselves, to establish roots. They do it spontaneously, simply because they were expelled. It is an almost vegetable process. Perhaps one can observe it when one tries to transplant trees. It can happen that the expelled becomes conscious of the vegetable, almost vegetative aspect of his exile; that he uncovers that the human being is not a tree; and that perhaps human dignity consists in not having roots—that a man first becomes a human being when he hacks off the vegetable roots that bind him. In German, there is the hateful word Luftmensch, a careless “man with his head in the air.” The expelled may discover that air and spirit are closely related terms and that therefore Luftmensch essentially signifies human being.
      </Typography>
      <Typography 
        paragraph variant='subtitle1'
      >
        This sort of discovery is a dialectical change in the relationship between expelled and expeller. Before this discovery, the expeller is the active pole and the expelled is the passive pole. After this discovery, the expeller is the victim and the expelled is the perpetrator. This is the discovery that history is made by the expelled, not by the expellers. The Jews are not part of Nazi history; the Nazis are part of Jewish history. The grandparents are not part of our biography; the grandchildren are part of our biography. We are not part of the history of automatic apparatuses; the apparatuses are part of our history. And, the more radically the Nazis, the grandchildren, and the apparatuses have driven us into exile, the more we make history: the better we transcend. But this is not the decisive part of the discovery that we are not trees—that the uprooted make history. Instead, the decisive part of it is to discover how tiresome it is not to establish new roots. After all, habit is not merely a cotton blanket that covers up everything. It is also a mud bath where it is nice to wallow. Homesickness is a nostalgie de la boue, and one can make oneself comfortable anywhere, even in exile. Ubi bene, ibi patria. The discovery that we are not trees challenges the expelled to struggle constantly against the seduction pleasures of the mud bath. To continue to experience expulsion, which is to say: to allow oneself to be expelled again and again.
      </Typography>
      <Typography 
        paragraph variant='subtitle1'
      >
        Of course, this leads us to the question of freedom. The discovery of human dignity as uprootedness seems to reduce one’s freedom to the mere right to come and go as one pleases. The right of the spirit to drift from one place to another. But, in reality, the question of freedom leads us to the question: Is it possible to allow oneself to want to be driven? Is there not a contradiction between “allowing” and “wanting”? Are we able to want our fate? A famous question. But, for the expelled, it is not a theoretical question, such as the dialectic between determination and freedom; rather, it is a practical question. The first expulsion was suffered. It has shown itself to be productive. Afterwards, exile becomes habit. Should one, like Baron von Münchhausen, try to pull oneself out of this habit by one’s own bootstraps, or should one provoke a new ex pulsion? Thus, the question of freedom is not the question of coming and going, but rather of remaining a stranger. Different from others.
      </Typography>
      <Typography 
        paragraph variant='subtitle1'
      >
        At the beginning, I said that creating is synonymous with data pro cessing. By that I meant that the production of new information (creating) depends on the synthesis of previous information. Such a synthesis consists in the exchange of information, just as it might be stored in one singular memory or in multiple memories. Thus, with respect to creating, one can speak of a dialectical process where the dialogue is either “internal” or “external.” The advent of the expelled in exile leads to “ex ternal” dialogues. This spontaneously causes an industrious creative activity in the vicinity of the expelled. He is a catalyst for the synthesis of new information. If, however, he becomes aware of his uprootedness as his dignity, then an “internal” dialogue begins within himself; which is to say, an exchange between the information he has brought with him, and an entire ocean with waves of information that toss around him in exile. The objective is the creation of meaning between the imported information and the chaos that surrounds him. If these “external” and “internal” dialogues are harmonized with each other, they transform in a creative manner not only the world, but also the original natives and the expelled. This is what I meant when I said what freedom means for the expelled: the freedom to remain a stranger, different from the others. It is the freedom to change oneself and others as well.
      </Typography>
      <Typography 
        paragraph variant='subtitle1'
      >
        The expelled is the Other of others. Which is to say, he is other for the others, and the others are other for him. He himself is nothing more than the Other of others. In this manner, he is able to “identify.” His advent in exile allows the original natives to uncover that they are unable to “identify” without him. Because of his advent in exile, the “self” is rent asunder, opening it up to others, to a being-with-others. This dialogic atmosphere that characterizes exile is not necessarily part of a mutual recognition, but rather, it is mostly polemical (not to mention murderous). For the expelled threatens the “particular nature” of the original natives; his strangeness calls them into question. But, even such a polemical dialogue is creative; for it leads to the synthesis of new information. Exile, no matter what form it takes, is a breeding ground for creative activity, for the new.
      </Typography>
      <br /><br /><br /><br />
      <Typography 
        paragraph
        style={{fontSize: '14px'}}
      >
        Vilém Flusser, Exile and Creativity (1984) in Vilém Flusser, Writings, edited by Andreas Ströhl, translated by Erik Eisel, University of Minnesota Press Minneapolis.
      </Typography>
      {/* <Typography 
        paragraph
        style={{fontSize: '14px'}}
      >
      <LinkTab
        color='inherit'
        href="https://static.skllchain.com/vilem-flusser-exile-and-creativity.pdf"
      >
        Download
      </LinkTab> 
      </Typography> */}

    </Layout>
  )

  return mainView
}

export default Default
